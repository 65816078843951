import React, { useEffect, useState } from "react";

import "./Footer.css";
import { IoLogoYoutube } from "react-icons/io";
import { MdTipsAndUpdates } from "react-icons/md";
import { FaTiktok, FaYoutube } from "react-icons/fa";
import { GrTwitter, GrInstagram, GrFacebook, GrLinkedin } from "react-icons/gr";
import { Link } from "react-router-dom";
import { date } from "yup";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState();

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    setCurrentYear(year);
  }, []);
  return (
    <div className='footer2'>
      <div className='inner-footer'>
        {/* <!--  for quick links  --> */}
        <div className='footer-items footer-items2'>
          <h3 className='quick'>Company</h3>
          <div className='border5'></div>
          <ul className='footer_list'>
            <a>
              <li>
                <Link to='/aboutTiptop'>About TipTopGo.com</Link>
              </li>
            </a>
            <a>
              <li>
                <Link to='/aboutIntel'>About Intellims</Link>
              </li>
            </a>
            <a>
              <li>
                {" "}
                <Link to='/register-hotel'>List your Hotel</Link>
              </li>
            </a>
            <a>
              <li>
                <Link to='/partnership'>Partnerships</Link>
              </li>
            </a>
            <a>
              <li>
                {" "}
                <Link to='/newsroom'>Newsroom</Link>
              </li>
            </a>
            <a>
              <li>
                <Link to='/investor'>Investor relations</Link>
              </li>
            </a>
            <a>
              <li>
                <Link to='/advert'>Advertising</Link>
              </li>
            </a>
          </ul>
        </div>

        {/* for some other links  */}
        <div className='footer-items footer-items2'>
          <h3>Policy</h3>
          <div className='border5'></div>
          <ul className='footer_list'>
            <a>
              <li>
                <Link to='/privacy-policy'>Privacy policy</Link>
              </li>
            </a>
            <a>
              <li>
                <Link to='/terms-of-use'>Terms of use</Link>
              </li>
            </a>
            <a>
              <li>
                {" "}
                <Link to='/hotels-terms-and-conditions'>
                  Hotels terms and conditions
                </Link>
              </li>
            </a>
            <a>
              <li>
                {" "}
                <Link to='/TipTopGo-Rewards'>TipTopGo Rewards Terms</Link>
              </li>
            </a>
            <a>
              <li>
                {" "}
                <Link to='/Hotel-Rewards'>Hotel Rewards Terms</Link>
              </li>
            </a>
            <a>
              <li>
                {" "}
                <Link to='/hotels-agreement'>Hotels Agreement</Link>
              </li>
            </a>
          </ul>
        </div>

        {/* for contact us info  */}
        <div className='footer-items footer-items2'>
          <h3>Help</h3>
          <div className='border5'></div>
          <ul className='footer_list'>
            <li class='hover-container'>
              <i>
                <MdTipsAndUpdates />
              </i>

              <Link>Tips to Booking</Link>

              <div class='hover-card'>
                <h4>Factors to consider</h4>
                <ul>
                  <li>Location</li>
                  <li>Budget</li>
                  <li>Amenities</li>
                  <li>Reviews</li>
                </ul>
              </div>
            </li>

            <li>
              <i className='fa fa-map-marker' aria-hidden='true'></i>
              <Link to='/contact'>General Support</Link>
            </li>
            {/* <li><i class="fa fa-phone" aria-hidden="true"></i>Plot 740, Nasir El Rufai Crescent,<br/> Guzape Hills, Abuja</li> */}
            <li>
              <i className='fa fa-envelope' aria-hidden='true'></i>{" "}
              <Link to='/cancel-Booking'>Cancel your hotel booking</Link>
            </li>
            <li>
              <i className='fa fa-envelope' aria-hidden='true'></i>{" "}
              <Link to='/Refund-policy'>
                Refund timelines, policies & processes
              </Link>
            </li>
            <li>
              <i className='fa fa-envelope' aria-hidden='true'></i>
              <Link to='/TipTopGo-Coupon'> Use a TipTopGo coupon</Link>
            </li>
            <li>
              <i className='fa fa-envelope' aria-hidden='true'></i>
              <Link to='/Checkin/ID'>Checkin / ID documents</Link>
            </li>
          </ul>

          {/* for social links  */}
          <div className='social-media'>
            <a
              href='https://twitter.com/infotiptopg/status/1639218025248313344?s=46&t=LJ5vRoXGTkSrsZJaSNnRvg'
              target='_blank'
            >
              <i>
                <GrTwitter />
              </i>
              {/* <GrTwitter className='fab'/> */}
            </a>
            <a
              href='https://www.instagram.com/tiptopgodotcom/?igshid=YmMyMTA2M2Y%3D'
              target='_blank'
            >
              <i>
                <GrInstagram />
              </i>
            </a>
            <a href='https://m.facebook.com/100089917366449/' target='_blank'>
              <i>
                <GrFacebook />
              </i>
            </a>
            <a
              href='https://www.linkedin.com/in/tiptopgo-dotcom-4722b626b'
              target='_blank'
            >
              <i>
                <GrLinkedin />
              </i>
            </a>
            <a href='#' target='_blank'>
              <i>
                <FaTiktok />
              </i>
            </a>
            <a href='#' target='_blank'>
              <i>
                <IoLogoYoutube />
              </i>
            </a>
          </div>
        </div>
      </div>

      {/* Footer Bottom start   */}
      <div className='footer-bottom'>
        <hr />
        Copyright &copy; Intellims Limited {currentYear}.
      </div>
    </div>
  );
};

export default Footer;
