import React from 'react'
import './GetApp.css'
import image from '../../assets/app2.png'
import playstore from '../../assets/playstore.png'
const GetApp = () => {
  return (
    <div className="app_section">
      <div className="app_parent">
        <img
          src={image}        
          alt="" 
        />
      </div>
      <div className="app_text">    
        <h2 >
          Get the <br />
          TipTopGo.com App           
        </h2>    
        <h4>Download the tiptopgo.com app and book  hotel instantly</h4>
        <p>Book any hotel instantly with our Andriod App or Ios App</p>
        <ul className='app_download'>
          <li className='downloadopt'>
           <a href='https://play.google.com/store/apps/details?id=com.intellims.tiptopgo' target="_blank"> <div className="download android">
              <img src={playstore}/>
              <div className='appleStore andstore'>    
              <span className="df">Download from</span>    
              <span className="dfn">Google Play</span>
              </div>
             
            </div></a>
          </li>
          <li className='downloadopt'>
           <a href='https://apps.apple.com/ng/app/tiptopgo/id6444825263' target='_blank'> <div className="download apple">
              <i className="fa fa fa-apple fa-3x"></i>
              <div className='appleStore'>
              <span className="df">Download from</span>
              <span className="dfn">App Store</span>
              </div>
             
            </div></a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default GetApp