import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import NavBar from "../../HomePage/NavBar/NavBar";
import moment from "moment";
import { SRLWrapper } from "simple-react-lightbox";
// import Search_Hotel from "../../HomePage/Search/Search";
// import ImageSlider from "../ImageSlider/ImageSlider";
import { ImLocation2 } from "react-icons/im";
import "./Hotel_info.css";
import HotelAmenities from "../HotelAmenities/HotelAmenities";
import HotelBooking from "../HotelBooking/HotelBooking";
import FrequentlyAsk from "../FrequentQuestion/FrequentlyAsk";
import GetApp from "../../HomePage/Get App/GetApp";
import Footer from "../../HomePage/Footer/Footer";
// import { useNavigate } from "react-router-dom";
// import Review from "../Reviews/Review";
import Subscribe from "../../HomePage/Subscribe/Subscribe";
import hotelplace from "../../assets/hotelplace.webp";
import {
  LoadingSkeleton,
  LoadingSkeletonAddress,
  LoadingSkeletonDescription,
} from "./LoadingSkeleton/LoadingSkeleton";
// import { ThreeDots  } from  'react-loader-spinner'

import axios from "../../utilities/axios";
import Search from "../../HomePage/Search/Search";

const HotelInfomation = () => {
  const [hotelImage, setHotelImage] = useState([]);
  const [roomsImage, setRoomsImage] = useState([]);
  const [mapImages, setMapImages] = useState([]);
  const [loadSkeleton, setLoadSkeleton] = useState(false);
  const [hasTaxLevies, setHasTaxLevies] = useState(false)

  const [indivHotel, setIndivHotel] = useState();
  const { hotelId, hotelName, mainHotelName } = useParams();

  let history = useLocation();

  

  useEffect(() => {
    const fetchHotelImg = async () => {
      const hotelImg = await axios.get(
        `/hotels/getHotelImages?hotelId=${hotelId}`
      );

      // console.log("hotelImg", hotelImg.data[0].imageUrl);
      setHotelImage(hotelImg.data[0].imageUrl);
    };

    fetchHotelImg();
  }, []);

  useEffect(() => {
    const getHotels = async () => {
      setLoadSkeleton(true);
      const replaceDashesWithSpaces = (str) => {
        return str.replace(/-/g, " ");
      };
      const stringWithSpaces = replaceDashesWithSpaces(hotelName);
      const getHotel = await axios.get(
        `/registrations/hotels?sourceType=TiptopgoWeb&genFilter=${mainHotelName}`
      );
      const filterHotel = getHotel.data.find((e) => e.hotelId === hotelId);
      setRoomsImage(filterHotel);
      setIndivHotel(filterHotel);
      setLoadSkeleton(false);
    };
    getHotels();
  }, []);

  useEffect(() => {
    let sourceType = "TiptopgoWeb";
    const today = new Date();
    const formattedToday = moment(today).format("YYYY-MM-DD HH:mm:ss");
    let tomorrow = new Date();
    let tom = moment(tomorrow.setDate(today.getDate() + 1)).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    const getRoomInfo = async () => {
      const rooms = await axios.get(
        `/hotels/${hotelId}/roomAvailabilitySummary?startDate=${formattedToday}&endDate=${tom}&sourceType=${sourceType}`
      );
      const combinedRooms = [];

      for (const room of rooms.data) {
        const [roomImage] = roomsImage?.roomTypeImages?.filter(
          (hotel) => hotel.roomTypeId == room.roomTypeId
        );

       

        // if (roomsImage.taxLevies.length > 0) {
        //   setHasTaxLevies(true);
        // }

        if (roomImage) {
          
          combinedRooms.push({
            ...room,
            ...roomImage,
            taxLevies: roomsImage.taxLevies,
            // hasTaxLevies: hasTaxLevies,
          });
        } else {
          combinedRooms.push({
            ...room,
            roomTypePicture: null,
            taxLevies: roomsImage.taxLevies,
            // hasTaxLevies: hasTaxLevies,
          });
        }
      }

      setMapImages(combinedRooms);
    };
    getRoomInfo();
  }, [roomsImage]);

  // console.log("mapImages", mapImages);
  return (
    <div className='main_parent_hotel_info'>
      <NavBar />
      <div className='searchBar'>
        <Search />
      </div>

      <div className='parent_hotel_name'>
        <section className='hotel_name_sec'>
          <h1>
            {(indivHotel && indivHotel.hotelName) || <LoadingSkeleton />}{" "}
          </h1>
          <p>
            <span className='locateIcon'>
              <ImLocation2 />
            </span>{" "}
            <span className='pAddress'>
              {(indivHotel && indivHotel.address) || <LoadingSkeletonAddress />}
            </span>
          </p>
        </section>
        <div className='review_hotel_rate'>
          {/* <span>
            <h3>7.5</h3>
            <p>
              Excellent <br /> <a>Based on 153 Guest Reviews</a>
            </p>
          </span> */}
        </div>
      </div>
      {/* <ImageSlider selectedInfo={history.state}/> */}

      <div className='hote_info_sec_parent'>
        <div className='hotel_info_sec'>
          <div>
            <HotelAmenities selectedInfo={indivHotel && indivHotel} />
          </div>

          <div className='hotel_info_text'>
            <p>
              {(indivHotel && indivHotel.hotelDescription) || (
                <LoadingSkeletonDescription />
              )}
            </p>
            {/* <div className='parentdisplayFlex'> */}
            <SRLWrapper>
              <div className='grid-container'>
                {mapImages.length <= 0
                  ? null
                  : mapImages &&
                    mapImages.map((gallery, i) => (
                      <div key={i} className='grid-item'>
                        {i === 2 ? (
                          <img
                            src={hotelImage}
                            alt='room'
                            className='zoom-image'
                          />
                        ) : (
                          <div>
                            <a href={gallery.roomTypePicture}>
                              <img
                                src={
                                  gallery.roomTypePicture === null
                                    ? hotelplace
                                    : gallery.roomTypePicture
                                }
                                alt='room'
                                className='zoom-image2'
                              />
                            </a>
                          </div>
                        )}
                      </div>
                    ))}
              </div>
            </SRLWrapper>
          </div>
        </div>

        <div>
          <HotelBooking
            selectedInfo={indivHotel}
            selectedHotel={indivHotel}
            // roomFlatDiscount={roomFlatDiscount}
          />
        </div>
      </div>
      <FrequentlyAsk selectedInfo={indivHotel} />

      {/* <Review/> */}

      <Subscribe />

      <GetApp />

      <Footer />
    </div>
  );
};

export default HotelInfomation;
