import React from "react";
import "./Book.css";
import { GetCurrencyData } from "../Redux/Actions/currency_actions";

const BookingDetails = ({
  bookingDetails2,
  useDiscount,
  historyData,
  payAtHotelWarning,
}) => {   
  const getSelectedCurrency = GetCurrencyData();
  const selectedCurrencyObject = getSelectedCurrency.selectedCurrency;
  const { currencySymbol, currencyRate, currencyCode } = selectedCurrencyObject;

  const convertAmount = (amount, rate, currencyCode) => {
    if (currencyCode === "NGN") {
      return amount * rate;
    } else {
      return amount / rate;
    }
  };

  return (
    <div>
      {bookingDetails2 &&
        bookingDetails2.map((detail) => (
          <div className='book_hotel__details2' key={detail.roomTypeId}>
            <div className='book__hotel_info'>
              <h4>
                <a>{detail.roomTypeName}</a>
                <p>
                  {currencySymbol || "₦"}{" "}
                  {payAtHotelWarning <= 0
                    ? convertAmount(
                        detail.totalCost,
                        currencyRate,
                        currencyCode
                      ).toLocaleString()
                    : convertAmount(
                        detail.roomCost,
                        currencyRate,
                        currencyCode
                      ).toLocaleString()}{" "}
                  <br />
                </p>
              </h4>

              <h4 className='qty'>
                {" "}
                Quantity <i>{detail.qty}</i>{" "}
              </h4>

              <h4 className='days'>
                {" "}
                Days <i>{historyData.days}</i>{" "}
              </h4>

              {detail.numOfBreakfast !== 0 && (
                <h4 className='days'>
                  BreakFast Cost{" "}
                  <i>
                    {" "}
                    {currencySymbol || "₦"}{" "}
                    {convertAmount(
                      detail.breakfastFlatCost,
                      currencyRate,
                      currencyCode
                    ).toLocaleString() || 0}
                  </i>{" "}
                </h4>
              )}

              <h4 className='days'>
                BreakFast Qty <i>{detail.numOfBreakfast}</i>{" "}
              </h4>

              {useDiscount && (
                <h4
                  className='days'
                  style={{ color: "red", fontSize: "0.6em" }}
                >
                  {" "}
                  Discount Amount{" "}
                  <i>
                    {convertAmount(
                      detail.discountPrice,
                      currencyRate,
                      currencyCode
                    )}
                  </i>{" "}
                </h4>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default BookingDetails;
