import React, { useState, useEffect } from "react";
import moment from "moment";
import "./History.css";
import { Loader } from "../../../utilities/Loader";
import axios from "../../../utilities/axios";
import { CalculateTax } from "../../../utilities/GeneralFunctions";

const History = () => {
  const [load, setLoad] = useState(false);
  const [reservationDetails, setReservationDetails] = useState([]);
  const [showHistory, setShowHistory] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showMessage, setShowMessage] = useState(true);
  const [reservations, setReservations] = useState();
  // const [totalTaxArray, setTotalTaxArray] = useState([]);
  // const [totalTaxSum, setTotalTaxSum] = useState(0);

  const userData = JSON.parse(localStorage.getItem("userData"));

  const guestId = userData.tipTopGoGuestId;

  useEffect(() => {
    const getReservation = async () => {
      setLoad(true);
      if (isOnline) {
        const reservation = await axios.get(
          `/registrations/getAppReservationRecords?filterType=bookHistory&tipTopGoGuestId=${guestId}`
        );
        // console.log("reservation", reservation);
        setReservationDetails(reservation.data);
        setLoad(false);

        if (reservation.data.length < 1) {
          setShowHistory(false);
        }
      }
    };
    getReservation();
  }, [isOnline, guestId]);

  //   let totalSum = 0;

  //  const updatedReservationsWithTotals = updatedReservations.map(
  //    (reservation) => {
  //      let totalTaxAmounts = {};

  //      for (const booking of reservation.bookings) {
  //        for (const taxLevy of reservation.taxLevies) {
  //          if (
  //            taxLevy.taxPercentage !== null &&
  //            taxLevy.roomId === booking.roomId
  //          ) {
  //            const taxAmount =
  //              (taxLevy.taxPercentage / 100) * booking.amountPaid;

  //             //  console.log("booking.amountPaid", booking.amountPaid);

  //            // Update the amount field in the taxLevy object
  //            taxLevy.amount = taxAmount;

  //            // Accumulate the amounts for the same tax label
  //            totalTaxAmounts[taxLevy.taxLabel] =
  //              (totalTaxAmounts[taxLevy.taxLabel] || 0) + taxAmount;
  //            totalSum += taxAmount;
  //          } else if (
  //            taxLevy.flatAmount !== null &&
  //            taxLevy.roomId === booking.roomId
  //          ) {
  //            // Use flatAmount directly if taxPercentage is null
  //            taxLevy.amount = taxLevy.flatAmount;

  //            // Accumulate the amounts for the same tax label
  //            totalTaxAmounts[taxLevy.taxLabel] =
  //              (totalTaxAmounts[taxLevy.taxLabel] || 0) + taxLevy.flatAmount;
  //            totalSum += taxLevy.flatAmount;
  //          }
  //        }
  //      }

  //      // Filter out duplicate taxLevies
  //      const uniqueTaxLevies = Object.values(
  //        reservation.taxLevies.reduce((acc, taxLevy) => {
  //          acc[taxLevy.taxLabel] = acc[taxLevy.taxLabel] || taxLevy;
  //          return acc;
  //        }, {})
  //      );

  //      // Create the totalTaxArray with amount, taxLabel, taxPercentage, and flatAmount for each taxLevy
  //      const totalTaxArray = uniqueTaxLevies
  //        .filter((taxLevy) => taxLevy.amount !== undefined) // Filter out taxLevies without amount
  //        .map((taxLevy) => ({
  //          taxLabel: taxLevy.taxLabel,
  //          amount: parseFloat(taxLevy.amount.toFixed(1)),
  //          taxPercentage: taxLevy.taxPercentage,
  //          flatAmount: taxLevy.flatAmount,
  //        }));

  //      return {
  //        ...reservation,
  //        totalTaxArray,
  //      };
  //    }
  //  );

  let totalSum = 0;

  useEffect(() => {
    reservationDetails.forEach((e, i) => {
      e.totalPay = e.bookings.reduce(
        (a, b) => a + b.amountToPay + e.breakfastCost,
        0
      );
    });

    reservationDetails.forEach((e, i) => {
      e.actualTotalAmountPaid = e.bookings.reduce(
        (a, b) => a + b.amountPaid,
        0
      );
    });

  

    const updatedReservations = reservationDetails.map((reservation) => {
      const discountPerDay = reservation.discountAmount * reservation.numOfDays;
      const totalCommissionDiscountAmount = reservation.bookings.reduce(
        (acc, booking) => acc + (booking.commissionDiscountAmount || 0),

        0
      );

      const totalNumOfRooms = reservation.bookings.reduce(
        (acc, booking) => acc + (booking.numOfRooms || 0),
        0
      );

      const totalAmountPaidOnDiscount =
        reservation.totalPay - totalCommissionDiscountAmount;

      let totalTaxAmounts = {};

      let sumTotalRoomCostOnDisReserv = 0;

      const roomTypeIds = new Set();
      reservation.bookings = reservation.bookings.filter((booking) => {
        if (roomTypeIds.has(booking.roomTypeId)) {
          return false;
        } else {
          roomTypeIds.add(booking.roomTypeId);
          return true;
        }
      });
      let result = 0;
      let resultPercentage = 0;
      let totalSumOfTax = 0;
      let amountWithTotalTaxAmount = 0;

      for (const booking of reservation.bookings) {
        const commissionDiscountAmount = booking.commissionDiscountAmount * booking.numOfRooms * reservation.numOfDays;
        const totalRoomCostNoDiscount = booking.amountToPay * booking.numOfRooms * reservation.numOfDays + reservation.breakfastCost;
        const totalRoomCostOnDiscount = totalRoomCostNoDiscount - commissionDiscountAmount;
        
        booking.commissionDiscountAmount = commissionDiscountAmount;
        booking.totalRoomCostNoDiscount = totalRoomCostNoDiscount;
        booking.totalRoomCostOnDiscount = totalRoomCostOnDiscount;

        const uniqueTaxLevies = Object.values(
          reservation.taxLevies.reduce((acc, taxLevy) => {
            acc[taxLevy.taxLabel] = acc[taxLevy.taxLabel] || taxLevy;
            return acc;
          }, {})
        );

        for (const taxLevy of uniqueTaxLevies) {
          if (taxLevy.taxPercentage !== null) {
            const taxAmount =
              (taxLevy.taxPercentage / 100) * booking.totalRoomCostOnDiscount;
            resultPercentage += taxAmount;
            taxLevy.amount = resultPercentage;

            totalTaxAmounts[taxLevy.taxLabel] =
              (totalTaxAmounts[taxLevy.taxLabel] || 0) + taxAmount;
            taxLevy.totalSum += taxLevy.amount;
          } else if (taxLevy.flatAmount !== null) {
            let taxAmount = taxLevy.flatAmount + reservation.breakfastCost;
            result += taxAmount * booking.numOfRooms;
            taxLevy.amount = result;

            totalTaxAmounts[taxLevy.taxLabel] =
              (totalTaxAmounts[taxLevy.taxLabel] || 0) + taxAmount;
            taxLevy.totalSum += taxLevy.amount;
          }
        }
      }

      const uniqueTaxLevies = Object.values(
        reservation.taxLevies.reduce((acc, taxLevy) => {
          acc[taxLevy.taxLabel] = acc[taxLevy.taxLabel] || taxLevy;
          return acc;
        }, {})
      );

      const totalTaxArray = uniqueTaxLevies
        .filter((taxLevy) => taxLevy.amount !== undefined)
        .map((taxLevy) => ({
          taxLabel: taxLevy.taxLabel,
          amount: Math.round(taxLevy.amount),
          taxPercentage: taxLevy.taxPercentage,
          flatAmount: taxLevy.flatAmount,
        }));

      for (const totalTaxAmoutVat of totalTaxArray) {
        totalSumOfTax += totalTaxAmoutVat.amount;
      }

      amountWithTotalTaxAmount = totalAmountPaidOnDiscount + totalSumOfTax;

      return {
        ...reservation,
        totalTaxArray,
        totalCommissionDiscountAmount,
        totalAmountPaidOnDiscount,
        totalNumOfRooms,
        sumTotalRoomCostOnDisReserv,
        totalSumOfTax,
        amountWithTotalTaxAmount,
      };
    });

    setReservations(updatedReservations);
  }, [reservationDetails]);

 
  
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    };

    const handleOffline = () => {
      setIsOnline(false);
      setShowMessage(true);
    };
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [setShowMessage]);

  return (
    <div className='history__parent'>
      <div className='history__p'>
        <div className='sideBar__div'>{/* <SideBar  /> */}</div>

        <div className='history__parent2'>
          <h1 className='history__header'>history</h1>
          {isOnline ? null : (
            <p className='offLine'>
              Network Error, please check your internet connection
            </p>
          )}

          {load ? (
            <div className='__header2'>
              <Loader />
            </div>
          ) : (
            <>
              {showHistory ? (
                <>
                  {reservations &&
                    reservations.map((detail) => (
                      <>
                        <span className='createdDate'>
                          {moment(detail.createdDateTime).format(
                            "YYYY-MMMM-DD : h:mm:ss"
                          )}
                        </span>
                        <table>
                          <tr className='tableHeader'>
                            <th>
                              {detail.hotelName} <br />
                              <span>{detail.location}</span>{" "}
                            </th>
                            {/* <th>Contact</th> */}
                          </tr>
                          <tr className='tableData'>
                            <td>Reference</td>
                            <td>{detail.paymentReference}</td>
                          </tr>
                          <tr className='tableData'>
                            <td>bookingCode</td>
                            <td>{detail.bookingCode}</td>
                          </tr>
                          <tr className='tableData'>
                            <td>Guest name</td>
                            <td>
                              {userData.firstName} {userData.lastName}
                            </td>
                          </tr>
                          <tr className='tableData'>
                            <td>Check-in Date</td>
                            <td>
                              {moment(detail.reservationStartDateTime).format(
                                "YYYY-MMMM-DD : h:mm:ss"
                              )}
                            </td>
                          </tr>
                          <tr className='tableData'>
                            <td>Check-out Date</td>
                            <td>
                              {moment(detail.reservationEndDateTime).format(
                                "YYYY-MMMM-DD : h:mm:ss"
                              )}
                            </td>
                          </tr>

                          <tr className='tableData tableData2'>
                            <th>Room Type</th>
                            <th>Day(s)</th>
                            <th>Qty</th>
                            <th>Price</th>
                          </tr>

                          {detail.bookings.map((book) => (
                            <tr className='tableData tabledata3'>
                              <td className='roomtyp'>{book.roomTypeName}</td>
                              <td>{detail.numOfDays}</td>
                              <td>{book.numOfRooms}</td>
                              <td>
                                {book.amountToPay *
                                  book.numOfRooms *
                                  detail.numOfDays +
                                  detail.breakfastCost *
                                    detail.numOfRooms *
                                    detail.numOfDays}
                              </td>
                            </tr>
                          ))}

                          <tr className='tableData tabledata3 subtotal'>
                            <td>Sub Total</td>
                            <td>{detail.totalPay?.toLocaleString()}</td>
                          </tr>

                          <tr className='tableData'>
                            <td>Discount Amount</td>
                            <td>
                              {detail.totalBill <= 0
                                ? 0
                                : detail.totalCommissionDiscountAmount?.toLocaleString()}
                            </td>
                          </tr>

                          <tr className='tableData'>
                            <td>Total</td>
                            <td>
                              {detail.totalBill <= 0
                                ? detail.totalPay?.toLocaleString()
                                : detail.totalAmountPaidOnDiscount?.toLocaleString()}
                            </td>
                          </tr>

                          {detail.totalTaxArray?.map((tax) => (
                            <tr className='tableData tabledata3 '>
                              <td
                                className='roomtyp tax'
                                style={{ color: "red" }}
                              >
                                {tax.taxLabel}
                              </td>
                              <td style={{ color: "red" }}>{tax.amount}</td>
                            </tr>
                          ))}

                          <tr className='tableData groundTotal'>
                            <td>Ground Total</td>
                            <td>
                              {detail.totalBill <= 0
                                ? 0
                                : detail.amountWithTotalTaxAmount?.toLocaleString()}
                            </td>
                          </tr>

                          <tr className='tableData'>
                            <td>Reservation status</td>
                            <td className='reserv'>{detail.reservationType}</td>
                          </tr>
                        </table>
                      </>
                    ))}
                </>
              ) : (
                <h1 className=' __header2'>
                  OOps!!! it seems you dont <br /> have any reservation yet
                </h1>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default History;
