import React, {useState, useEffect} from 'react'
//import emailjs from 'emailjs-com'
// import logo from '../../assets/logo.jpeg'
import NavBar from '../../HomePage/NavBar/NavBar'
import Banner from '../../utilities/Banner'
import '../Receipt/Receipt.css'
import hotelplace from '../../assets/hotelplace.webp'
import {  useLocation } from "react-router-dom";
import logo from '../../assets/TiptopGoLogo.png'
import Footer from '../../HomePage/Footer/Footer'
import Subscribe from '../../HomePage/Subscribe/Subscribe'


const Receipt = ({historyData, refer, name, name2}) => {
    const [currentDate, setCurrentDate] = useState()
    const [days, setDays] = useState()
         
    let history = useLocation();
    const [showReceipt, setShowReceipt] = useState(false)
    const [showDiscount, setShowDiscount] = useState(false)


    const reservationHistory = history.state[0];

    // console.log("reservationHistory", reservationHistory)

    const showDetails = () => {
        if(!reservationHistory){
           
            setShowReceipt(!showReceipt)
        }
        
       
    }



    const date = new Date()

let date_1 = new Date (reservationHistory && reservationHistory.reservationStartDateTime);
 let date_2 = new Date(reservationHistory && reservationHistory.reservationEndDateTime) ;

 
 
 const dayz = (date_1, date_2) =>{
    
     let difference = (date_2 - date_1);
     let TotalDays = Math.ceil(difference / (1000 * 3600 * 24))-1;
     return TotalDays;
  
 }

 useEffect(()=>{
  setDays(dayz(date_1, date_2))
 
//   console.log(dayz(date_1, date_2) +" nights");
  showDetails()  
 },[])

 
 const totalAmountPaid = reservationHistory && reservationHistory.bookings.reduce((a, b) => a + b.amountPaid, 0)

//  console.log("totalA", totalAmountPaid);

const numOfDays =  reservationHistory && reservationHistory.numOfDays
const getDiscountAmount = reservationHistory && reservationHistory.discountAmount

const mainAmountPaid = totalAmountPaid - getDiscountAmount

    const currDate = () => {
        var bookingDay = new Date()

        let currentDate2 = bookingDay.getFullYear() + "/" + bookingDay.getMonth()+1 + "/" +  bookingDay.getDate()

        setCurrentDate(currentDate2)

    }

    useEffect(()=>{
        currDate()

        const checkDiscount = () =>{
                if(getDiscountAmount <= 0){
                    setShowDiscount(false)
                }else{
                    setShowDiscount(true)
                }
              }

              checkDiscount()
    },[])

          
  return (
    <div className='first_parent_receipt'>
    <NavBar/>

    {/* <Banner className="banner"/> */}
    {      
        showReceipt ?
        
        <div class='parent_receipt'>
        <div class='receipt_head'>
            
            <div class='receipt_head2'>
            <img src={logo} alt="logo" />
            </div>
        </div>
        
        <div className='receipt_body' style={{ paddingLeft:'25%'}}>
            <p></p>
             <p></p>
           
           
            <p><h4 >No Record Found</h4> </p>
           
            

            </div>
        {/* <div className='footer'>tiptapgo</div> */}
    </div> 
        
        : 
        
        <div class='parent_receipt'>
        <div class='receipt_head'>
            
            <div class='receipt_head2'>
            <img src={logo} alt="logo" />
            </div>
        </div>
        
        <div className='receipt_body'>
            <p><span >Name:</span> {reservationHistory && reservationHistory.firstName} {reservationHistory && reservationHistory.lastName}</p>
             <p> <span>Total Bill:</span> &#8358;{totalAmountPaid}</p>
             {
                showDiscount ? (
                    <>
                    <p> <span>Discount Amount:</span> &#8358;{getDiscountAmount}</p>
                     <p> <span>Amount Paid:</span> &#8358;{mainAmountPaid}</p>
           
                    </>
                ) : null
             }
           
            <p>Booking Date: {reservationHistory && reservationHistory.createdDateTime} </p>
           
            <p>Payment Reference: {reservationHistory && reservationHistory.paymentReference} </p>

            <div className="invoice-box">
            <p className='hotName'>
               <p><span >Hotel Name:</span> <span>{reservationHistory && reservationHistory.hotelName}</span></p> 
                <span >Hotel Address:</span><span> {reservationHistory && reservationHistory.address}</span>
                
                </p>
                <table cellPadding="0" cellSpacing="0">
                    <tr class="top">
                        
                        <td colSpan="4">
                            <table>
                           
                                <tr>

                                
                                    <td class="title">
									 <div className='hotelplace'><img src={hotelplace} /></div> 
								    </td>
    
                                    <td className='title2'>
                                        <p><span >Booking Code:</span> {reservationHistory && reservationHistory.bookingCode} </p>
                                        <p><span >checkInDate:</span>{reservationHistory && reservationHistory.reservationStartDateTime}  </p>
                                        <p><span >checkOutDate: </span>{reservationHistory && reservationHistory.reservationEndDateTime} </p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
    
                    <tr className="information">
                        <td colSpan="2">
                            {/* <table> */}
                                {/* <tr>
                                    <td>
                                        tiptopgo, LTD.<br />
                                        3c Durban street<br />
                                        Wuse 2, Abuja
                                    </td>
    
                                    <td>
                                        customerservice.<br />
                                        tiptopgo@example.com
                                    </td>
                                </tr> */}
                            {/* </table> */}
                        </td>
                    </tr>
    
                    {/* <!-- <tr class="heading">
                        <td>Payment Method</td>
    
                        <td>Check #</td>
                    </tr> --> */}
    
                    {/* <!-- <tr class="details">
                        <td>Check</td>
    
                        <td>1000</td>
                    </tr>
     --> */}
                    <tr className="heading">
                        <td>Room Type</td>
     
                        <td>Quantity</td>

                        <td>Day(s)</td>
    
                        <td>Price</td>
                    </tr>

                    {
                       reservationHistory && reservationHistory.bookings.map((book, index)=>(
                            <tr className="item">
                            <td>{book.roomTypeName}</td>
    
                            <td>{book.numOfRooms}</td>
    
                            <td>{numOfDays}</td>
                            {/* <td>&#8358;{book.roomCost}</td> */}
                            <td>&#8358;{book.amountPaid}</td>
                        </tr>
                        ))
                    }
    
                   
    
                   
                    <tr className="total">
                        <td></td>
                        <td>Total Room Cost: {" "}&#8358;{totalAmountPaid}</td>
                    </tr>
                    {
                        showDiscount ? <><tr className="total total2">
                        <td></td>
                        <td>Amount Paid: {" "}&#8358;{mainAmountPaid}</td>
                    </tr></> : null
                    }
                </table>
            </div>
        </div>

        {/* <div className='footer'>tiptapgo</div> */}
    </div>
    }
    
    <Subscribe/>
    <Footer/>
    </div>
)

    
}

export default Receipt 