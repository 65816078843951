import React, {useState, useRef, useEffect} from 'react'
import LocationList from './LocationList/LocationList'
import './Search.css'
import { useNavigate } from 'react-router-dom'
import AdultNo from './AdultNo/AdultNo'
import {DatePicker} from 'antd';
import moment from 'moment'
import dayjs from 'dayjs';
import axios from '../../utilities/axios'
import { ThreeDots  } from  'react-loader-spinner'

const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD : HH:mm';


const Search = ({listItem, setPropertyType}) => {
  const [showDrop, setShowDrop] = useState(false);
  const [showCount, setShowCount] = useState(false);
  const [dates, setDates] = useState([]);
  const [value, setValue] = useState("");
  const [count, setCount] = useState(1)
  const [count1, setCount1] = useState(0)
  const [count2, setCount2] = useState(1)
  const [hotelId, setHotelId] = useState()
 const [searchResultChange, setSearchResultChange] = useState();   
 const [isLoading, setIsLoading] = useState(false);

 const [loading, setLoading] = useState(false)   

 let availableHotel;
 let hotelList;
 const navigation = useNavigate();

 const defDate = new Date()
 let defDate2 = moment(defDate).format('YYYY-MM-DD: HH:mm')
 
const today = new Date()
let tomorrow =  new Date()
let tom= moment(tomorrow.setDate(today.getDate() + 1)).format('YYYY-MM-DD: HH:mm')

const joinDates = [defDate2, tom]



useEffect(()=>{
  setDates(joinDates);
},[])

// console.log("date", dates)

  const onSearch = (searchTerm, searchHotelId) => {
    setValue(searchTerm);
    setHotelId(searchHotelId);
  };

  const normalizeInput = (input) => {
    // Normalize the input by removing spaces between letters and numbers
    return input
      .toLowerCase()
      .replace(/\s*(\d+)/g, "$1")
      .replace(/\s+/g, "");
  };

  const onChange = async (event) => {
    const rawValue = event.target.value; // Allow spaces in the input
    const normalizedValue = normalizeInput(rawValue); // Normalize for search

    setValue(rawValue); // Display the raw input
    setShowDrop(rawValue.length > 0); // Show dropdown only if there's input
    setLoading(true);

    if (rawValue.length === 0) {
      setSearchResultChange([]); // Clear search results when input is empty
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `/registrations/hotels?genFilter=${normalizedValue}&sourceType=TiptopgoWeb&pageSize=12&page=1`
      );
      const searchResult = response.data;
      setSearchResultChange(searchResult);
    } catch (error) {
      console.error("Error fetching search results:", error);
      // Handle error here, e.g., set error message in state
    }

    setLoading(false);
  };


  // /registrations

  const countIncrease = () =>{
    setCount(count+1)
  }

  const countIncrease2 = () =>{
    setCount(count-1)
  }

  
  const showElement = () => {
    setCount1(count1 + 1)
   
}

const hideElement =() => {
  setCount1(count1 - 1)
  
}

const increaseRoom = () =>{
  setCount2(count2+1)
}

const decreaseRoom = () =>{
  setCount2(count2-1)
}


  let locationList1 = useRef();

  let locationList2 = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (locationList1.current && !locationList1.current.contains(event.target)) {
        // setShowDrop(false);
        locationList1.current.style.display = 'none';
      }
    };

    document.addEventListener("click", handler);

    return () => {
      document.addEventListener("click", handler);
    };
  },[locationList1]);

  useEffect(() => {
    let handler2 = (event) => {
      if (locationList2.current && !locationList2.current.contains(event.target)) {
        // setShowCount(false);
        locationList2.current.style.display = 'none';
      }
      
    };

   document.addEventListener("click", handler2);

    return () => {
      document.addEventListener("click", handler2);
    };
  },[locationList2]);

//  const disablePastDates = (current) =>  current && current < moment().endOf("day");

  let handleShow = () =>{
    setShowDrop(false);
  }

  let handleShow2 = () => {
    setShowCount(!showCount)
  }

  const onClick = async (event) => {   
    event.preventDefault();     
  localStorage.removeItem('pageNo')
  navigation(`/hotels/${value}/${dates}`, {state: {
    availableHotel, dates, value
  }})
  // handlePullRooms();
}

  return (
    <div className="search_hotelparent">
      <div className="search_hotel">
        <form className="inputs">
          <input
            type="text"
            placeholder="where are you going"
            className="location locationInput"
            onClick={handleShow}
            value={value.trimStart()}
            onChange={onChange}
          />
          {showDrop && (
            <div ref={locationList1} className="locationHandler">
              <LocationList
                onSearch={onSearch}
                value={value}
                availableHotel={searchResultChange}    
                handleShow={handleShow}
                loading={loading}
              />
            </div>
          )}
          {showCount && (
            <div ref={locationList2} className="countHandler">
              <AdultNo
                countDecrease={countIncrease2}
                countIncrease={countIncrease}
                countIncrease2={showElement}
                countDecrease2={hideElement}
                countIncrease3={increaseRoom}
                countDecrease3={decreaseRoom}
                countVal={count}
                countVal2={count1}
                countVal3={count2}
              />
            </div>
          )}
          <RangePicker
            className="location location2"
            allowClear={false}
            disabledDate={(current) => {
              let customDate = moment().format("YYYY-MM-DD");
              return current && current < moment(customDate, "YYYY-MM-DD");
            }}
            defaultValue={[dayjs(defDate2, dateFormat), dayjs(tom, dateFormat)]}
            onChange={(valm) => {
              //const newDates = convertDateTimeToOBj(valm);
              setDates(
                valm.map((item) => {
                  const selectedDate = new Date(item);
                  return moment(selectedDate).format("YYYY-MM-DD : HH:mm");
                })
              );
            }}
          />
          {isLoading ? (
            <button className="submit2">
              Loading
              <ThreeDots
              height="10"
              width="50"
              radius="9"
              color="#fff"
              ariaLabel="three-dots-loading"
              wrapperStyle={{marginLeft:'45%', marginTop:'-10%'}}
              // wrapperClassName=""
              visible={true}
              wrapperClassName="lo"
            />
            </button>
            
          ) : (
            <button
              className="submit"    
              onClick={(e) => onClick(e)}
              disabled={value === "" ? true : false}
            >
              {" "}
              Search
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

export default Search

