import React,{useState} from 'react'
import './Profile.css'
// import Footer from '../../../HomePage/Footer/Footer'
// import GetApp from '../../../HomePage/Get App/GetApp'
// import NavBar from '../../../HomePage/NavBar/NavBar'
// import Subscribe from '../../../HomePage/Subscribe/Subscribe'
import {AiFillEdit} from 'react-icons/ai'
import {BsSave2Fill} from 'react-icons/bs'
import {ImCancelCircle} from 'react-icons/im'
import profile from '../../../assets/profile.jpg'
import axios from '../../../utilities/axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import SideBar from '../SideBar/SideBar'
import * as Yup from "yup"
import { useFormik } from 'formik'

const Profile = () => {
    const [showEdit, setShowEdit] = useState(false)
    const userData = JSON.parse(localStorage.getItem('userData'));
    const [firstName] = useState(userData.firstName)
    const [lastName] = useState(userData.lastName)
    const [email] = useState(userData.emailAddress)
    const [phoneNumber] = useState(userData.phoneNumber)
    const [address] = useState(userData.address)
    // const [disable, setDisable] = useState(false)
   

    // console.log("userData",userData)

    const handleShowEdit = () =>{
        setShowEdit(!showEdit)
    }

    // const getFirstName = (e) =>{
    //     setFirstName(e.target.value)
    //     if(firstName === ""){
    //         setDisable(true)
    //     }else{
    //         setDisable(false)
    //     }
    // }

    // const getLastName = (e) =>{
    //     setLastName(e.target.value)
    // }

    // const getEmail = (e) =>{
    //     setEmail(e.target.value)
    // }

    // const getPhoneNo = (e) =>{
    //     setPhoneNumber(e.target.value)
    // }

    // const getAddress = (e) =>{
    //     setAddress(e.target.value)
    // }

    const notifySuccess = (res) => {
        toast.success(res.data.message,{
         position: "top-right",
         autoClose: 2500,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,     
         progress: undefined,
         theme: "dark",
       
       })
    }

    const formik = useFormik({
        initialValues: {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            emailAddress: email,
            address: address,
          tipTopGoGuestId: userData.tipTopGoGuestId,
        },
        validationSchema: Yup.object({    
          emailAddress: Yup.string().email("invalid email address").required("Email is Required"),
          firstName: Yup.string().min(2, "first name is required").required(" Required"),
          lastName: Yup.string().min(2, "last name is required").required(" Required"),
          address: Yup.string().min(2, "Address can't be empty").required(" Required"),
        }),
      
        onSubmit:  async (values) => {
          // console.log(values)
      
          await axios.put('registrations/updateUserProfile', values)
          .then((res) => {
            localStorage.setItem('userData', JSON.stringify(res.data.guestDetails))
                notifySuccess(res)
                setTimeout(()=>{
                    handleShowEdit()
                },3000)
          })
          .catch(err => console.log(" Login unsuccessfull", err))
        }
      })
      

    // const handleUpdateInfo = async () =>{
    //     await axios.put('registrations/updateUserProfile',{
    //         tipTopGoGuestId: userData.tipTopGoGuestId,
    //         firstName: firstName,
    //         lastName: lastName,
    //         phoneNumber: phoneNumber,
    //         emailAddress: email,
    //         address: address
    //     }).then((res)=>{
    //         // console.log("res",res)
    //         localStorage.setItem('userData', JSON.stringify(res.data.guestDetails))
    //         notifySuccess(res)
    //         setTimeout(()=>{
    //             handleShowEdit()
    //         },3000)
           
    //     }).catch((err)=>{console.log("err",err)})

        
    // }

   
  return (
    <div className='main__parent_profil'>     
        {/* <NavBar/> */}
        <div className='profile__side'> 
        {/* <SideBar/> */}
         <div className='parent__profile'>
            <div className='profileImg'>
                <img src={profile} alt='userpic'/>
            </div>

            
                {
                    showEdit ? <form onSubmit={formik.handleSubmit} className='user__det'>
                    <p className='edit__info'><span className='label '>First<br/> Name:</span><input name='firstName' id='firstName' value={formik.values.firstName} onChange={formik.handleChange} onBlur={formik.handleBlur}  placeholder='change name' className='label2'/>
                    {formik.touched.firstName && formik.errors.firstName ? <p className='error'>{formik.errors.firstName}</p>: null}
                    </p>
                    <p className='edit__info'><span className='label'>Last<br/> Name:</span><input className='label2' name='lastName' id='lastName' value={formik.values.lastName} onChange={formik.handleChange} onBlur={formik.handleBlur}  placeholder='change name'/>
                    {formik.touched.lastName && formik.errors.lastName ? <p className='error'>{formik.errors.lastName}</p>: null}
                    </p>
                    <p className='edit__info'><span className='label '>Email:</span><input className='label3' id='emailAddress' name='emailAddress' type='email' value={formik.values.emailAddress} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='change email'/>
                    {formik.touched.emailAddress && formik.errors.emailAddress ? <p className='error'>{formik.errors.emailAddress}</p>: null}
                    </p>
                    <p className='edit__info'><span className='label'>Phone <br/>Number:</span><input className='label4' value={phoneNumber}  placeholder='change name' disabled/></p>

                    <p className='edit__info'><span className='label'>Address:</span><input   className='label5' id='address'  name='address' type='address' value={formik.values.address} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder='change address'/>
                    {formik.touched.address && formik.errors.address ? <p className='error'>{formik.errors.address}</p>: null}
                    </p>
                   <div className='update__btn'>
                   <button className='edit__btn'><BsSave2Fill/>Save Changes</button>
                    <button className='edit__btn' onClick={handleShowEdit}><ImCancelCircle/>Cancel Changes</button>
                   </div>
                    
                </form> :
                        <div className='user__det'>
                        <p className='userDetails'><span className='lab'>First Name</span> <br/> <span>{userData.firstName}</span></p>
                        <p className='userDetails'><span className='lab'>Last Name</span> <br/> <span>{userData.lastName}</span></p>
                        <p className='userDetails'><span className='lab'>Email</span> <br/> <span>{userData.emailAddress}</span></p>
                        <p className='userDetails'><span className='lab'>Phone Number</span> <br/> <span>{userData.phoneNumber}</span></p>
                        <p className='userDetails'><span className='lab'>Address</span> <br/> <span>{userData.address}</span></p>
                        <button className='edit__btn edit__btn2' onClick={handleShowEdit}><AiFillEdit/>Edit Profile</button>
                    </div>
                }
                 

          </div>
        </div>
        <ToastContainer />
        {/* <Subscribe myStyle={bookStyle.backgroundColor}/>
        <GetApp/>
        <Footer/> */}
    </div>
  )
}

export default Profile