import React, {useState}from 'react'
import { Link } from 'react-router-dom'
import NavBar from '../../HomePage/NavBar/NavBar'
import axios from "../../utilities/axios"
import { useNavigate } from 'react-router-dom'
// import {  useLocation } from "react-router-dom";
import './Login.css'
import * as Yup from "yup"
import { useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import {FaSignInAlt} from 'react-icons/fa';
  import {
    GetCartData
  } from '../../Redux/Actions/room_actions';
import {MdMarkEmailRead} from 'react-icons/md'
import reg from '../../assets/login.png'
import Subscribe from '../../HomePage/Subscribe/Subscribe'
import Footer from '../../HomePage/Footer/Footer'
import { InfinitySpin } from  'react-loader-spinner'


const Login = () => {

const [showErr, setShowErr] = useState(false)
const [loader, setLoader] = useState(false)

const storeData = GetCartData();

const notify = (res) => {
  toast.success(res.data.message,{
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  
  })
}

const notifySuccess = (res,values) => {
  toast.success("Your login was successful",{
   position: "top-right",
   autoClose: 2000,
   hideProgressBar: false,
   closeOnClick: true,
   pauseOnHover: true,
   draggable: true,
   progress: undefined,
   theme: "dark",
 
 })
localStorage.setItem('userData', JSON.stringify(res.data.guestDetails)) 

if(res.data.guestDetails.active === "true"){
  localStorage.setItem('loggInStatus', 'true');
}


// const generateRandomCode = (length) => {
//   const characters = '0123456789';
//   let code = '';
//   const charactersLength = characters.length;

//   for (let i = 0; i < length; i++) {
//     const randomIndex = Math.floor(Math.random() * charactersLength);
//     code += characters.charAt(randomIndex);
//   }

//   return code;
// }; 

// const randomCode = generateRandomCode(5);
// // get local stiorage
const historyData = JSON.parse(localStorage.getItem('historyData'));
   setTimeout(()=>{
     if(storeData.length > 0) {
      navigation(`/book`, {state: 
        historyData
     })
    } else {
      navigation('/LoginDash/main-dashboard', {state: {
        values
       }})
    }
     
   },2000)
 }

 
const navigation = useNavigate();

// let history = useLocation();
const formik = useFormik({
  initialValues: {
    emailAddress: "",
    password: ""
  },
  validationSchema: Yup.object({    
    emailAddress: Yup.string().matches(/^[^\s]+$/, 'Email must not contain spaces').email('Invalid email address').required('Email is required'),
    password: Yup.string().max(20, "Must be 6 characters or less").required("password is Required"),
  }),

  onSubmit:  async (values) => {
    setLoader(true)

    await axios.post('/registrations/accessApp', values)
    .then((res) => {
      if(res.data.message === "user does not exist"){
        notify(res)
          setShowErr(true)
          setTimeout(()=>{
            setShowErr(false)
          },3000)
          
         
      }else if(res.data.success === false){
        
        notify(res)
        
        setShowErr(true)
        setTimeout(()=>{
          setShowErr(false)
        },3000)

      }else{
           notifySuccess(res, values)
      }
    })
    .catch(err => console.log(" Login unsuccessfull", err))

      setLoader(false)
 
  }
  
})


  return (
    <div className='parent_login'>
      <NavBar />

      {/* <Banner /> */}
      <div className='loginSwapLog'>
        <div className='loginIm'>
          <img src={reg} alt='liquid' />
        </div>
        <div className='containerLogin2'>
          <form className='login_form' onSubmit={formik.handleSubmit}>
            <p>
              Welcome!!!
              <br /> Sign In To Continue
            </p>
            <div>
              {" "}
              <label for='name'>
                Email Address<span class='required-field'></span>
              </label>
              <div className='passwordInput'>
                <input
                  type='email'
                  placeholder='email Address'
                  value={formik.values.emailAddress.trimStart()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='emailAddress'
                  id='emailAddress'
                />
                <MdMarkEmailRead className='togglePass2' />
              </div>
            </div>
            <br />
            {formik.touched.email && formik.errors.email ? (
              <p className='error'>{formik.errors.email}</p>
            ) : null}
            <div>
              {" "}
              <label for='name'>
                Password<span class='required-field'></span>
              </label>
              <div className='passwordInput'>
                <input
                  //  type={showPassword ? 'text' : 'password'}
                  type='password'
                  placeholder='Password'
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name='password'
                  id='password'
                />
              </div>
              {/* {showPassword ? <BsFillEyeSlashFill onClick={setShowPass} className='togglePass'/>:  <AiFillEye onClick={setShowPass} className='togglePass'/> } */}
            </div>
            <br />
            {formik.touched.password && formik.errors.password ? (
              <p className='error'>{formik.errors.password}</p>
            ) : null}
            {showErr ? (
              <span className='indicateError'>incorrect Login details</span>
            ) : null}
            {loader ? (
              <button className='load'>
                <InfinitySpin width='120' color='#fff' />
              </button>
            ) : (
              <>
                <button type='submit'>
                  <FaSignInAlt className='signinIcon' />
                  Login
                </button>{" "}
                <br />
              </>
            )}

            <span className='forget'>
              <Link to='/confirmEmail'>Forgot Password?</Link>
            </span>
            <div className='regHere'>
              <span>
                Have an account? <br /> <Link to='/register'>Register</Link>
              </span>
            </div>
          </form>
        </div>
        <div></div>
      </div>
      <ToastContainer />
      <Subscribe />
      <Footer />
    </div>
  );
}

export default Login