import React,{useState} from 'react'
import './AdultNo.css'

const AdultNo = ({countVal, countVal2,countVal3, countIncrease, countDecrease, countIncrease2, countDecrease2, countIncrease3, countDecrease3}) => {
   
    const [count2, setCount2] = useState(1)

    const [showAge, setShowAge] = useState(true)

    return (
      <div className='Adult_parent'>
        <div className="firstButton">
            <h5>Adult</h5>
            <div className='main_button'>
          <button onClick={countDecrease} disabled={countVal === 1}>-</button>
          <h2>{countVal}</h2>
          <button onClick={countIncrease}>+</button> 
          </div>
          </div>

          <div className="firstButton">
            <h5>children</h5>
            <div className='main_button2'>
          <button onClick={countDecrease2} disabled={countVal2 === 0}>-</button>
          <h2>{countVal2}</h2>
          <button onClick={countIncrease2}>+</button> 
          </div>
          </div>

          {showAge && (
            <div className='showAgediv'>
            <select className='selectopt'>
            <option>Age needed</option>
            <option>0 year old</option>
            <option>1 year old</option>
            <option>2 years old</option>
            <option>3 years old</option>
            <option>4 years old</option>
            <option>5 years old</option>
            <option>6 years old</option>
            <option>7 years old</option>
            <option>15 years old</option>
          </select>
          <p>To find you a place to stay that fits your entire group along with correct prices, we need to know how old your child will be at check-out</p>
          </div>
          )}


          <div className="firstButton">
            <h5>Room</h5>
            <div className='main_button'>
          <button onClick={countDecrease3} disabled={countVal3 === 1}>-</button>
          <h2>{countVal3}</h2>
          <button onClick={countIncrease3}>+</button> 
          </div>
          </div>

          <div className='done_button'>
            <button>Done</button>
          </div>
      </div>
    )
  }

export default AdultNo