import React, { useEffect, useState } from "react";
// import './HotelsList.css'
import Footer from "../../HomePage/Footer/Footer";
// import {FiWifi} from 'react-icons/fi'
// import {AiFillCar} from 'react-icons/ai'
// import {FaAirFreshener, FaSwimmer} from 'react-icons/fa'
// import {CgGym} from 'react-icons/cg'
// import {RiRestaurantFill} from 'react-icons/ri'
// import {BiDrink} from 'react-icons/bi'
// import {BiSwim} from 'react-icons/bi'
import Filters from "../Filters/Filters";
import NavBar from "../../HomePage/NavBar/NavBar";
import Search from "../../HomePage/Search/Search";
import GetApp from "../../HomePage/Get App/GetApp";
import { Link } from "react-router-dom";
// import axios from 'axios'
import HotelsComponents from "./HotelsComponents";
import { useLocation, useParams } from "react-router-dom";
import ScrollToTop from "../../utilities/scrollTo";
import HotelsLoader from "../../utilities/HotelsLoader";
import Subscribe from "../../HomePage/Subscribe/Subscribe";
import axios from "../../utilities/axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SiInfluxdb } from "react-icons/si";
import { BsPatchCheckFill } from "react-icons/bs";
import { BsHouseCheckFill } from "react-icons/bs";
import { FaHotel } from "react-icons/fa";
import { BsFilterLeft } from "react-icons/bs";
import { BiSolidBinoculars } from "react-icons/bi";
import { Modal, filterModal } from "./Modal/Modal";
import { LoadingSkeletonHotelList } from "../../IndividualHotel/Hotel_Info/LoadingSkeleton/LoadingSkeleton";
import BookingSummary from "../../Book/BookingSummary2/BookingSummary";

const HotelsList = () => {
  const [propertyTypeClicked, setPropertyTypeClicked] = useState(false);
  const [showHotels, setShowHotels] = useState(false);
  const [showfilter, setShowFilter] = useState(false);
  const [receivedData, setReceivedData] = useState("");
  const [filterLoader, setFilterLoader] = useState();
  const [isFixed, setFixed] = useState(false);
  const [showForm, setShowform] = useState(false);
  const [activeCategory, setActiveCategory] = useState("Our Top Picks");
  const [filterResult, setFilterResult] = useState([]);
  const [mainPageLoader, setMainPageLoader] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [genLoader, setGenLoader] = useState(false);
  const [propShow, setPropShow] = useState();
  const [newHotelCount, setNewHotelCount] = useState();
  const [showPaginate, setShowPaginate] = useState();
  const [showSearch, setShowSearch] = useState();
  const [showBtn, setShowbtn] = useState(false);
  const [mobilePriceFilter, setmobilePriceFilter] = useState(false);
  const [mobilePriceFilterResult, setmobilePriceFilterResult] = useState([]);
  const [showPrompt, setShowPrompt] = useState(false);
  const [availableHotel, setAvailableHotel] = useState([]);
  const [filterObj, setFilterObj] = useState({
    priceStartRange: 0,
    priceEndRange: 0,
    amenities: [],
    // selectedCity: "",
  });
  const { seachName, dates } = useParams();

  useEffect(() => {
    const getHotelsOnPageLoad = async () => {
      setMainPageLoader(true);
      localStorage.removeItem("pageNo");
      try {
        const hotelsLocation = await axios.get(
          `/registrations/hotels?genFilter=${
            seachName === "FCT-Abuja" ? "FCT" : seachName
          }&sourceType=TiptopgoWeb&pageSize=12&page=1`
        );
        // console.log("hotelsLocation", hotelsLocation.data);
        setAvailableHotel(hotelsLocation.data);
      } catch (error) {
        console.error("Error fetching hotel data");
      }
      setMainPageLoader(false);
    };

    getHotelsOnPageLoad();
  }, [seachName]);

  let historyData =
    filterResult.length !== 0 ? filterResult : availableHotel && availableHotel;

  if (mobilePriceFilter === true) {
    historyData = mobilePriceFilterResult;
  }

  let totalHotel = 0;
  if (historyData.length < 1) {
    totalHotel = 0;
  } else {
    totalHotel = historyData[0].hotelCount;
  }
  const handlePropertysetState = (newState) => {
    // console.log("totalHotel", totalHotel);
    setPropertyTypeClicked(newState);
  };
  useEffect(() => {
    if (totalHotel < 1) {
      setShowHotels(false);
    } else {
      setShowHotels(true);
    }

    if (availableHotel) {
      setPropertyTypeClicked(false);
    }

    if (historyData.length < 10) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
  }, [totalHotel, availableHotel]);

  const searchStyle = {
    marginTop: "50%",
  };

  const handleData = (data, category, filterLoader) => {
    // console.log("data", data)
    if (data.length > 0) {
      setPropertyTypeClicked(true);
      setPropShow(category);
      totalHotel = data.length;
      setNewHotelCount(totalHotel);
      setShowPaginate(false);
      // console.log("totalHotel", totalHotel)
    }

    if (category === "all property") {
      setPropertyTypeClicked(false);
      totalHotel = historyData[0].hotelCount;
      setNewHotelCount(totalHotel);
      setShowPaginate(true);
      setShowSearch(false);
    }

    if (data.length < 1) {
      setShowPrompt(true);
    } else {
      setShowPrompt(false);
    }

    setFilterLoader(filterLoader);
    setReceivedData(data);
  };

  // fix search at the top
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const fixedThreshold = window.innerHeight * 0.1; // 10% of the viewport height

      if (scrollPosition >= fixedThreshold) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const notify = (res) => {
    toast.success(res.data.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const notifySuccess = (res) => {
    toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const formik = useFormik({
    initialValues: {
      emailAddress: "",
    },
    validationSchema: Yup.object({
      emailAddress: Yup.string()
        .email("invalid email address")
        .required("email is required"),
    }),

    onSubmit: async (values) => {
      setShowform(true);
      await axios
        .post("/registrations/createSubscribers", values)
        .then((res) => {
          if (res.data.message === "subscription created successfully") {
            notify(res);
          } else {
            notifySuccess(res);
          }
        })
        .catch((err) => console.log(" subscription unsuccessfull", err));
    },
  });
  const pageNumber = localStorage.getItem("pageNo");

  // if(category === "all property"){
  //   setPropertyTypeClicked(false)
  //   totalHotel = historyData[0].hotelCount
  //   setNewHotelCount(totalHotel)
  //   setShowPaginate(true)
  //   setShowSearch(false)
  // }

  const handleCategoryClick = async (category) => {
    setGenLoader(true);
    setActiveCategory(category);
    setPropertyTypeClicked(false);
    setShowSearch(false);

    setPropShow("all property");
    const newHotels = await axios.get(
      `/registrations/hotels?genFilter=${seachName}&sourceType=TiptopgoWeb&pageSize=12&page=1`
    );
    setFilterResult(newHotels.data);
    setGenLoader(false);
    setShowPrompt(false);
    if (newHotels.data.length > 0) {
      totalHotel = newHotels.data[0].hotelCount;
      setNewHotelCount(totalHotel);
    }
    closeModal();
  };

  const handlePropertyTypeClick = async (category) => {
    setGenLoader(true);
    setPropertyTypeClicked(true);
    setPropShow(category);
    setShowPrompt(false);
    let filterResult = await axios.get(
      `/registrations/getAdvanceSearch?genFilter=${seachName}&sourceType=TiptopgoWeb&propertyTypeFilter=${category}`
    );
    // setFilterCatLoader(false)
    setFilterResult(filterResult.data);
    setGenLoader(false);
    if (filterResult.data.length > 0) {
      totalHotel = filterResult.data.length;
      setNewHotelCount(totalHotel);
      // console.log("totalHotel", totalHotel)
    }
    closeModal();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openSecondModal = () => {
    setIsSecondModalOpen(!isSecondModalOpen);
  };

  const getFilterPrice = (e) => {
    const { value, checked } = e.target;
    const startEndPrice = value.split(",");
    const startPrice = parseFloat(startEndPrice[0]);
    const endPrice = parseFloat(startEndPrice[1]);

    if (checked) {
      setFilterObj((prevFilterObj) => ({
        ...prevFilterObj,
        priceStartRange: startPrice,
        priceEndRange: endPrice,
      }));
      setShowbtn(true);
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        if (checkbox.value !== value) {
          checkbox.checked = false;
        }
      });
    } else {
      setFilterObj((prevFilterObj) => ({
        ...prevFilterObj,
        priceStartRange: 0,
        priceEndRange: 0,
      }));
      setShowbtn(false);
    }
  };

  let advancedFilter = filterObj;

  const searchFilteredOpt = async () => {
    // setFilterloader(true)
    setGenLoader(true);
    let filterResult = await axios.get(`/registrations/getAdvanceSearch`, {
      params: {
        advancedFilter: advancedFilter,
        genFilter: seachName,
        sourceType: "TiptopgoWeb",
        pageSize: 12,
        page: 1,
        propertyTypeFilter: propShow === "all property" ? "" : propShow,
      },
    });

    // console.log("filterResult", filterResult.data, propShow)
    if (filterResult.data.length > 0) {
      totalHotel = filterResult.data.length;
      setNewHotelCount(totalHotel);
    }
    setmobilePriceFilter(true);
    setPropertyTypeClicked(true);
    setmobilePriceFilterResult(filterResult.data);
    setActiveCategory(propShow);
    setShowSearch(false);
    setIsSecondModalOpen(!isSecondModalOpen);
    setGenLoader(false);
  };

  useEffect(() => {
    if (historyData.length > 0) {
      setShowPrompt(false);
    } else {
      setShowPrompt(true);
    }
  }, [historyData]);

  return (
    <div>
      <NavBar />

      <div
        className={
          propertyTypeClicked === true || showSearch === false
            ? "displayNone"
            : ""
        }
      >
        {/* <div className={`fixed-div ${isFixed ? "fixed" : "schBar"}`}> */}
        <div className='schBar'>
          <Search setPropertyType={handlePropertysetState} />
        </div>
      </div>

      {mainPageLoader ? (
        <HotelsLoader />
      ) : (
        <div className='hotelListdiv__'>
          {genLoader ? (
            <LoadingSkeletonHotelList />
          ) : (
            <div
              className={
                propertyTypeClicked === true || showSearch === false
                  ? "secondparent_hotels"
                  : "parent_hotels"
              }
            >
              {/* {showHotels ? ( */}
              <div className='upperDiv'>
                <div className='hotels_list_header'>
                  <h2 className='no_hotel'>
                    {newHotelCount > 0 ? newHotelCount : totalHotel}{" "}
                    {propShow ? propShow : "Hotels"} in {seachName}
                  </h2>
                  <h3 className='action'>
                    Select & book from{" "}
                    {newHotelCount > 0 ? newHotelCount : totalHotel}{" "}
                    {propShow ? propShow : "Hotels"} around {seachName}
                  </h3>
                  <p className='hotels_text_header'>
                    Book a great {propShow ? propShow : "Hotels"} in {seachName}
                    . TipTopGo.com has luxury hotels, cheap hotels, lodges,
                    airbnbs in {seachName}, short-let apartments and more. All
                    at the best rates that are only available online!
                  </p>
                  {/* <ul className='hotels_category'>
      <li
        className={
          activeCategory === "Our Top Picks" ? "activeCategory " : "filtCat"
        }
        onClick={() => handleCategoryClick("Our Top Picks")}
      >
        <BsFilterLeft /> | Our Top Picks
      </li>
      <li
        className={activeCategory === "luxury" ? "activeCategory" : "filtCat"}
        onClick={() => handleCategoryClick("luxury")}
      >
       <SiInfluxdb/> | Luxury Hotels
      </li>
      <li
        className={activeCategory === "cheap" ? "activeCategory" : "filtCat"}
        onClick={() => handleCategoryClick("cheap")}
      >
        <BsPatchCheckFill/> | Cheap Hotels
      </li> */}
                  {/* <li
        className={activeCategory === "apartment" ? "activeCategory" : "filtCat"}
        onClick={() => {
          handlePropertyTypeClick("apartment");
          setActiveCategory("apartment");
        }}
      >
       <BsHouseCheckFill/> | Apartment
      </li> */}

                  {/* <li
        className={activeCategory === 'Best Hotels' ? 'activeCategory' : ''}
        onClick={() => handleCategoryClick('Apartment')}
      >
        Apartment
      </li> */}
                  {/* <li
        className={activeCategory === "hotel" ? "activeCategory" : "filtCat"}
        onClick={() => {
          handlePropertyTypeClick("hotel");
          setActiveCategory("hotel");
        }}
      >
       <FaHotel/> | Hotel
      </li> */}
                  {/* </ul> */}
                  <div className='mobileFiterOpt'>
                    <button onClick={openModal} className='mobileSearchFilter'>
                      {" "}
                      <BsFilterLeft /> | Property type
                    </button>

                    <button onClick={openSecondModal}>
                      {" "}
                      <BsFilterLeft /> | search filter
                    </button>
                  </div>

                  <Modal isOpen={isModalOpen} onClose={closeModal}>
                    <h2>property type</h2>
                    {/*<p>This is the content of the modal.</p> */}
                    <ul className='mobileCategory'>
                      <li
                        className={
                          activeCategory === "Our Top Picks"
                            ? "activeCategory"
                            : "filtCat"
                        }
                        onClick={() => handleCategoryClick("Our Top Picks")}
                      >
                        <BsFilterLeft /> | All property
                      </li>
                      {/* <li className={activeCategory === "luxury" ? "activeCategory" : "filtCat"}
        onClick={() => handleCategoryClick("luxury")}
        ><SiInfluxdb/> | Luxury Hotel</li>
        <li className={activeCategory === "cheap" ? "activeCategory" : "filtCat"}
        onClick={() => handleCategoryClick("cheap")}
        ><BsPatchCheckFill/> | Cheap Hotel</li> */}
                      <li
                        className={
                          activeCategory === "apartment"
                            ? "activeCategory"
                            : "filtCat"
                        }
                        onClick={() => {
                          handlePropertyTypeClick("apartment");
                          setActiveCategory("apartment");
                        }}
                      >
                        {" "}
                        <BsHouseCheckFill /> | Apartments
                      </li>
                      <li
                        className={
                          activeCategory === "hotel"
                            ? "activeCategory"
                            : "filtCat"
                        }
                        onClick={() => {
                          handlePropertyTypeClick("hotel");
                          setActiveCategory("hotel");
                          // closeModal()
                        }}
                      >
                        <FaHotel /> | Hotels
                      </li>
                    </ul>
                    {/* <button onClick={closeModal}>Close</button> */}
                    {/* Add the item you want to click to close the modal */}
                    {/* <div onClick={closeModal}>Click to Close</div> */}
                  </Modal>
                  {isSecondModalOpen && (
                    <div className='filter_item1'>
                      <p>What is your Budget</p>
                      <ul>
                        <div className='search_amenities'>
                          <input
                            type='checkbox'
                            id='bar'
                            value='0,5000'
                            checked={
                              filterObj.priceStartRange === 0 &&
                              filterObj.priceEndRange === 5000
                            }
                            onChange={getFilterPrice}
                          />
                          <li> &#x20A6;0 - &#x20A6;5,000</li>
                        </div>

                        <div className='search_amenities'>
                          <input
                            type='checkbox'
                            id='bar'
                            value='6000,10000'
                            checked={
                              filterObj.priceStartRange === 6000 &&
                              filterObj.priceEndRange === 10000
                            }
                            onChange={getFilterPrice}
                          />
                          <li> &#x20A6;6,000 - &#x20A6;10,000</li>
                        </div>

                        <div className='search_amenities'>
                          <input
                            type='checkbox'
                            id='bar'
                            value='11000,16000'
                            checked={
                              filterObj.priceStartRange === 11000 &&
                              filterObj.priceEndRange === 16000
                            }
                            onChange={getFilterPrice}
                          />
                          <li> &#x20A6;11,000 - &#x20A6;16,000</li>
                        </div>

                        <div className='search_amenities'>
                          <input
                            type='checkbox'
                            id='bar'
                            value='17000,27000'
                            checked={
                              filterObj.priceStartRange === 17000 &&
                              filterObj.priceEndRange === 27000
                            }
                            onChange={getFilterPrice}
                          />
                          <li> &#x20A6;17,000 - &#x20A6;27,000</li>
                        </div>

                        <div className='search_amenities'>
                          <input
                            type='checkbox'
                            id='bar'
                            value='28000,10000000'
                            checked={
                              filterObj.priceStartRange === 28000 &&
                              filterObj.priceEndRange === 10000000
                            }
                            onChange={getFilterPrice}
                          />
                          <li> &#x20A6;28,000 and above </li>
                        </div>
                      </ul>
                      {showBtn ? (
                        <button onClick={searchFilteredOpt}>Search</button>
                      ) : null}
                    </div>
                  )}
                </div>
                <div className='subscribeDiv'>
                  <p>Stay updated with our latest rates</p>
                  <form className='inputDiv' onSubmit={formik.handleSubmit}>
                    {showForm ? (
                      <span className='subnoticeMSG'>
                        Thank you for subscribing to our Newsletter, we will
                        keep you updated with the latest deals and rate on
                        TiptopGo.com
                      </span>
                    ) : (
                      <>
                        <input
                          type='email'
                          placeholder='email'
                          value={formik.values.emailAddress.trimStart()}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name='emailAddress'
                          id='emailAddress'
                          className='subEmailInput'
                        />
                        <button className='subBtn'>Subcribe</button>
                        <br />
                      </>
                    )}
                  </form>
                  {formik.touched.emailAddress && formik.errors.emailAddress ? (
                    <p className='error'>{formik.errors.emailAddress}</p>
                  ) : null}
                </div>
              </div>

              {filterLoader && filterLoader ? (
                <HotelsLoader />
              ) : (
                <div className='parent_individual_hotel '>
                  {showPrompt === true ? (
                    <span className='showHotelSecond'>
                      <div className='showHotelSecondIcon'>
                        <BiSolidBinoculars className='mainIcon' />
                      </div>
                      We couldn't find any hotels that match your search
                      criteria. Please check your filters or try a different
                      search to discover the perfect hotel for your needs
                    </span>
                  ) : (
                    <div className='parent_hotels_comp'>
                      <HotelsComponents
                        hotelList={historyData}
                        propTypeclick={propertyTypeClicked}
                        defaultDate={dates}
                        value={seachName}
                        Link={Link}
                        receivedData={receivedData}
                        newHotelCount={newHotelCount}
                        showPaginate={showPaginate}
                      />
                    </div>
                  )}

                  <ToastContainer />
                  {/* <div className='filter_section'> */}
                  {showfilter && (
                    <Filters
                      value={seachName}
                      historyData={historyData}
                      sendData={handleData}
                    />
                  )}

                  {/* </div> */}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {/* <Subscribe/> */}
      <GetApp />
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default HotelsList;
