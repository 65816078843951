import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import './Todays_deal.css'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from '../../utilities/axios'
import moment from 'moment'  
import {Loader} from '../../utilities/Loader'   

const Todays_deal = () => {
const [hotels, setHotels] = useState([])

const [inputValue, setInputValue] = useState([])

const [inputValue2, setInputValue2] = useState([])
const [isOnline, setIsOnline] = useState(navigator.onLine);

const [load, setLoad] = useState(false)


//console.log("hotels===>>>wwuwu", inputValue2);

const navigation = useNavigate();

  
useEffect(()=>{
   setLoad(true)
   if(isOnline){
  const getAxios = async () =>{
   
    //  const hotels = await axios.get(`/registrations/hotels?sourceType=TiptopgoWeb`); 
     const hotels = await axios.get(`/registrations/hotels?&sourceType=TiptopgoWeb&pageSize=12&page=1`);
       
     setHotels(hotels.data)
     setLoad(false)
  }
  getAxios();
}
}, [isOnline]);


 useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const handleOnline = () => {
    setIsOnline(true);
    
  };

  const handleOffline = () => {
    setIsOnline(false);
   
  };



 const getNewValue = (e) =>{
  let newVal = e.target.value
  setInputValue(newVal)


  for(const hotel of hotels){
    setInputValue2(hotel.hotelName)

    if(hotel.hotelName === inputValue){
      inputValue2.filter()
    }
  }

 
  // if(inputValue === )
 }

const defDate = new Date()
let defDate2 = moment(defDate).format('YYYY-MM-DD: HH:mm')

const today = new Date()
let tomorrow =  new Date()
let tom= moment(tomorrow.setDate(today.getDate() + 1)).format('YYYY-MM-DD: HH:mm')

const joinDates = [defDate2, tom]

const generateRandomCode = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let code = '';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    code += characters.charAt(randomIndex);
  }

  return code;
};

const passInfo = (hotel) => {
  const replaceSpacesWithDashes = (str) => {
    return str.replace(/\s+/g, '-');
  };
  const randomCode = generateRandomCode(20);
  const hotelId = hotel.hotelId
  const hotelName = hotel.hotelName
  const replacedString = replaceSpacesWithDashes(hotelName);

  navigation(`/hotel_info/${hotelId}/${randomCode}/${replacedString}/${hotelName}`, {state: {
    indivHotel: hotel, allResult2: joinDates,    
    hotelId, hotelName,     
  }})
}

const loaderStyl = {
  marginLeft: "45%"
}

// console.log("hotels", hotels)
  return (
    <div className='parent__Deals'>     

      <div className='deals'>
        <h4>Today's Top Hotel Deals</h4>
        <p>A selection of the best hotel deals, only available today</p>
        <hr />
         {isOnline ?  null : <p className='offLine2'>Network Error, please check your internet connection</p>}
        {/* <div className='today_search'>
        <input type='text' placeholder="search hotel" className='searchField' value={inputValue} onChange={getNewValue}/>
          <button onClick={getSearch} className='searchFieldBtn'><GoSearch className='searchicons99'/></button>
        </div> */}


        {
          load ?  <Loader /> :  <ul className="cards2"> 
                
          {
            hotels && hotels.map((hotel, i)=>(
              
                <li className='badge' key={i}  onClick={()=>passInfo(hotel)}  >
                  <i>Up to {" "} <span className='percentageAmt'>{hotel.tiptopGoCommission}%</span> {" "} off</i>
                  <div className="card" >   

                    <Link>
                      <img src={hotel.picture} className="card__image" alt="" />
                      <div className="card__overlay">
                        <div className="card__header">
                          <svg className="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>

                          <div className="card__header-text" key={hotel.hotelId}>
                            <h3 className="card__title"  >{hotel.hotelName}</h3>
                            <span className="card__status" >{hotel.location}</span>
                          </div>
                        </div>
                        <p className="card__description">{hotel.shortDescription}</p>
                      </div>
                    </Link>
                  </div>
                </li>
              
            )).slice(0,6)
          }
        </ul>
        }
       
      </div>
    </div>
    
  )
}

export default Todays_deal