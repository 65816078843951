import React, { useState, useEffect } from "react";
import "./LocationList.css";
import { GoLocation } from "react-icons/go";

import ClipLoader from "react-spinners/ClipLoader";
//var data = require("../../../Data.json");

const LocationList = ({ onSearch, availableHotel, handleShow, loading }) => {
  const [location, setLocation] = useState([]);

  const [showLocation, setShowLocation] = useState(false);

  useEffect(() => {
    setLocation(availableHotel);
    // setShowLocation(true)
  }, []);

  const uniqueLocations = [];
  const areNamesEqualIgnoringWhitespaces = (name1, name2) => {
    if (name1 === null || name2 === null) {
      return false; // Return false if either name is null
    }

    const regex = /\s/g;
    return (
      name1.replace(regex, "").toLowerCase() ===
      name2.replace(regex, "").toLowerCase()
    );
  };

  useEffect(() => {
    availableHotel &&
      availableHotel.forEach((location) => {
        if (location.hotelCity !== null) {
          // Check if hotelCity is not null
          const exists = uniqueLocations.some((uniqueLocation) =>
            areNamesEqualIgnoringWhitespaces(
              uniqueLocation.hotelCity,
              location.hotelCity
            )
          );
          if (!exists) {
            uniqueLocations.push(location);
          }
        }
      });
    setLocation(uniqueLocations);
  }, [availableHotel]);

  // useEffect(()=>{

  //   availableHotel && availableHotel.forEach((location) => {
  //     const exists = uniqueLocations.some((uniqueLocation) => areNamesEqualIgnoringWhitespaces(uniqueLocation.location, location.location));
  //     if (!exists) {
  //       uniqueLocations.push(location);
  //     }

  //     setLocation(uniqueLocations);

  //   });
  // },[availableHotel])

  // console.log("location", location)

  return (
    <div className='dropdown'>
      <h4> Location</h4>

      {loading && (
        <ClipLoader
          color={"#1E53A9"}
          loading={loading}
          size={35}
          data-testid={loading}
          className='loader'
        />
      )}

      <ul className='dropList'>
        {location &&
          location.map((hotel) => (
            <>
              <li
                onClick={() => onSearch(hotel.location, handleShow())}
                key={hotel.hotelId}
              >
                <GoLocation className='dropicon' />
                {hotel.hotelCity}
              </li>
            </>
          ))}
      </ul>

      <h3>Popular Nearby Hotel</h3>

      <ul className='dropList'>
        {availableHotel &&
          availableHotel
            .map((hotel) => (
              <>
                <li
                  onClick={() =>
                    onSearch(hotel.hotelName, hotel.hotelId, handleShow())
                  }
                  key={hotel.hotelId}
                >
                  <GoLocation className='dropicon' />
                  {hotel.hotelName}
                  <p>{hotel.hotelCity}</p>
                </li>
              </>
            ))
            .slice(0, 6)}
      </ul>
    </div>
  );
};

export default LocationList;
