import React, { useState } from 'react'
import './Subscribe.css'
import axios from '../../utilities/axios'
import * as Yup from "yup"
import { useFormik } from 'formik'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

const Subscribe = ({myStyle}) => {

  const [showForm, setShowform] = useState(false)

  const notify = (res) => {
    toast.success(res.data.message,{
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,     
      progress: undefined,
      theme: "dark",
    
    })
  }

  const notifySuccess = (res) => {
    toast.success(res.data.message,{
     position: "top-right",
     autoClose: 5000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     draggable: true,
     progress: undefined,
     theme: "dark",
   
   })
 
 }


  const formik = useFormik({
    initialValues: {
      emailAddress: ""
    },
    validationSchema: Yup.object({    
      emailAddress: Yup.string().email("invalid email address").required("email is required")
    }),
  
    onSubmit:  async (values) => {
      setShowform(true)  
      await axios.post('/registrations/createSubscribers', values)
      .then((res) => {
        if(res.data.message === "subscription created successfully"){
          notify(res)
         
        }else{
          notifySuccess(res)
        }
      })
      .catch(err => console.log(" subscription unsuccessfull", err))
    }
  })
  

  return (
    <div className='newsLetter-main'>
      <div className='newLetter_card'>
        <h2>Subscribe to our Newsletter</h2>
        {showForm ? (
          <span className='noticeMSG'>
            Thank you for subscribing to our Newsletter, <br /> we will keep you
            updated with the latest deals on TiptopGo.com
          </span>
        ) : (
          <form className='email' onSubmit={formik.handleSubmit}>
            <input
              type='email'
              placeholder='email'
              value={formik.values.emailAddress.trimStart()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name='emailAddress'
              id='emailAddress'
              className='subscribe'
            />

            {/* <i className="icon fa fa-envelop"></i> */}

            <button type='submit'>Subscribe</button>
            <br />
            {formik.touched.emailAddress && formik.errors.emailAddress ? (
              <p className='error'>{formik.errors.emailAddress}</p>
            ) : null}
          </form>
        )}
      </div>
      <ToastContainer />
    </div>
  );    
}

export default Subscribe