import React, {useState, useRef, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import abuja from '../../assets/Abuja.jpg'
import anambra from '../../assets/Anambra.jpg'
import enugu from '../../assets/Enugu.jpg'
import benue from '../../assets/Benue.jpg'
import imo from '../../assets/Imo.jpg'
import lagos from '../../assets/Lagos.jpg'

import { HotelsBg } from '../../City/HotelCityImg'

import axios from '../../utilities/axios'
import moment from 'moment'
import './Destination.css'
import {Loader, SecondLoader} from '../../utilities/Loader'
import HotelsLoader from "../../utilities/HotelsLoader";

const Destinations = () => {
 const [mainStateCount, setMainStateCount] = useState([])
  const [stateHotels, setStateHotels] = useState([]);
  const [dates, setDates] = useState([]);
  const [load, setLoad] = useState(false)
  const [load2, setLoad2] = useState(false)
  const [bgImage, setBgImage] = useState()
  const [randData, setRandData] = useState([]);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const navigation = useNavigate();
  let availableHotel;

  
  useEffect(()=>{
    setLoad2(true)
    if(isOnline){
      const getStateHotel = async () => {
        const hotels = await axios.get(`/registrations/hotelsInState`);
        
        setStateHotels(hotels.data);
        setLoad2(false)
      };
      getStateHotel()
    }
   
  }, [isOnline]);


  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const handleOnline = () => {
    setIsOnline(true);
    
  };

  const handleOffline = () => {
    setIsOnline(false);
   
  };

  

  const defDate = new Date();
  let defDate2 = moment(defDate).format("YYYY-MM-DD: HH:mm");

  const today = new Date();
  let tomorrow = new Date();
  let tom = moment(tomorrow.setDate(today.getDate() + 1)).format("YYYY-MM-DD: HH:mm");

  const joinDates = [defDate2, tom];
  useEffect(() => {  
    const pullData = () => {
    setDates(joinDates);
    const images = HotelsBg.map((image)=>{
     return image.backgroundImg
    })
    const randomIndex = Math.floor(Math.random() * images.length);
    const selectedPicture = images[randomIndex];
    const newStateImage = [];
    if(stateHotels.length > 0) {
      for(const stateImage of stateHotels) {
       
        const [finalStateImage] = HotelsBg.filter((e) => e.hotelCity === stateImage.name);
        if(finalStateImage){
          newStateImage.push({...finalStateImage, ...stateImage});
        }

      }
    }
    // const randomIndex2 = Math.floor(Math.random() * newStateImage.length);
     const  shuffledData =  newStateImage.sort(() => Math.random() - 0.5);
     
    setBgImage(selectedPicture)
    setMainStateCount(shuffledData)

    // console.log("shuffledData", shuffledData.slice(0,6))
  } 
  pullData()
  }, [stateHotels]);

  // function shuffleArray(array) {
  //   const shuffled = array.slice();
  //   for (let i = shuffled.length - 1; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  //   }
  //   return shuffled;
  // }
  
  // // Global variables
  // let shuffledItems = shuffleArray(mainStateCount);
  // let displayedItems = [];
  
  // // Function to display a specified number of items
  // function displayRandomItems(count) {
  //   if (shuffledItems.length === 0) {
  //     // If all items have been displayed, reshuffle the array
  //     shuffledItems = shuffleArray(mainStateCount);
  //     displayedItems = [];
  //   }
  
  //   for (let i = 0; i < count; i++) {
  //     const item = shuffledItems.pop();
  //     displayedItems.push(item);
  //     console.log("displayedItems", displayedItems); // Replace with your display logic
  //   }
  // }
  
  // // Example: Display 3 random items
  // displayRandomItems(8);


  // console.log("RandData", randData)
  const bgStyle = {
        backgroundImage: `url(${bgImage})`,
        height: "100%",    
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }

  // console.log("bgImage", bgImage)
     
  const getHotelsInfo = async (event, statehotel) => {
    setLoad(true)
    let value = statehotel.hotelCity;
    // const hotelsLocation = await axios.get(
    //   `/registrations/hotels?genFilter=${value === "FCT-Abuja" ? "FCT": value }&sourceType=TiptopgoWeb&pageSize=12&page=1`
    // );

    // const realData = hotelsLocation.data;

    // availableHotel = realData;
    localStorage.removeItem('pageNo')
    navigation(`/hotels/${value}/${dates}`, {
      state: {
        // availableHotel,
        dates,
        value: value === "FCT-Abuja" ? "FCT": value,
        statehotel
      },
    });

    setLoad(false)
  }

  const myDestination={
    backgroundColor:"#E7ECF3",
    width:"100vw",
    height:"150vh",
    zIndex: 1000,
    position:"fixed",
    top: "10%",
    bottom: 0,
    left: 0,
    right: 0,
    opacity:"0.5",
    display:"flex",
    alignItems:"center",
    justifyContent: "center",
    color:"#fff"
  }


  return (   
       <>
      
    <section className="destination_parent2">
    
      <h4>Suggested Destinations in Nigeria</h4>
      <p>Below are the most popular travel destinations in Nigeria</p>
      <hr className="destina_hr" />
      {isOnline ?  null : <p className='offLine'>Network Error, please check your internet connection</p>}
      {
        load2 ? <Loader/> : null
      }
      {
      load ? <SecondLoader/> : null
     }
    <div className='list_div333'>
      <ul className='destination_list'>   
        {
          mainStateCount && mainStateCount.map((statehotel, i)=>(
            <li key={i} onClick={(e)=> getHotelsInfo(e, statehotel)} style={{backgroundImage: `url(${statehotel.backgroundImg })`, backgroundSize:bgStyle.backgroundSize, backgroundPosition:bgStyle.backgroundPosition, backgroundRepeat:bgStyle.backgroundRepeat }}>    
            {" "}
            <h5> {" "} </h5> <p></p> <p className='stateName'></p> 
            
            <p className='stateCount'>{statehotel.totalNumberOfHotels}</p>{" "}
            <hr className="li_horizontal"  />
          </li>     
          )).slice(0,8)
        }
       
        
      </ul>
      </div>

      
      
     
      
    </section>
    </>
  );
}  

export default Destinations